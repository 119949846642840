<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">공지사항</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">공지사항 관리</h3>
          </template>

          <div>
            <!--      검색 영억      -->
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>

              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <div class="col-lg-12 pr-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="검색어 입력" aria-label="검색어 입력" aria-describedby="button-addon2"
                           v-model="search.keyword"
                           @keydown.enter="getList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="getList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <!--      목룍 영억      -->
            <el-table :data="noticeList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'등록된 게시글이 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column label="번호" min-width="10%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>
              <el-table-column prop="subject" label="제목"   min-width="50%">
                <template slot-scope="scope">
                  <div class="align-items-center">
                    <i class="ni ni-notification-70 text-red" v-if="scope.row.priorityLevel === 'Y'"></i>
                    <router-link :to="'notice-detail/'+scope.row.noticeSeq+ '?referer=' + $route.path + params" class="link_detail link_txt emph_g">
                      {{ scope.row.subject }}
                    </router-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="regDate"   label="작성일"  min-width="20%"></el-table-column>
              <el-table-column prop="visitCnt"  label="조회수"  min-width="20%"></el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="getList">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';

  export default {
    name: 'NoticeList',
    mixins: [
      commPaginationMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      params() {
        return '&page=' + this.pagination.currentPage + '&perPage=' + this.pagination.perPage + '&keyword=' + this.search.keyword;
      }
    },
    filters: {

    },
    created() {
      if(this.$route.query.page) this.pagination.currentPage = Number(this.$route.query.page);
      if(this.$route.query.perPage) this.pagination.perPage = Number(this.$route.query.perPage);
      if(this.$route.query.keyword) this.search.keyword = this.$route.query.keyword;
      this.getList(this.pagination.currentPage);
    },
    data() {
      return {
        isModify          : false,
        noticeSeq         : '',
        noticeList        : [],
        search: {
          keyword         : ''
        }
      };
    },
    methods: {
        /**
       * 목록 조회
       */
      getList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
          this.$axios.get(`${USER_API_PREFIX}/board/notice-list`, {
          params: {
            page          : pageNo,
            rows          : this.pagination.perPage,
            searchKeyword : this.search.keyword
          }
        }).then(res => {
          this.noticeList = res.data.result.noticeList;   // 목록정보 세팅
          this.pagination.currentPage = pageNo;           // 페이지 번호 세팅
          this.pagination.total = res.data.result.total;  // 전체페이지수 세팅
        });
      }
    }
  };
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
